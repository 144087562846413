/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ReactElement } from "react";
import dynamic from "next/dynamic";

const LoginLayout = dynamic(() => import("layouts/LoginLayout"));
const LoginScreen = dynamic(() => import("screens/AuthScreen/LoginScreen"));

export const getServerSideProps = async (ctx: any) => {
  const setCacheHeader = await import("helpers").then(
    (mod) => mod.setCacheHeader
  );

  setCacheHeader?.(ctx.res, 0);
  const { getCommonServerSideProps } = await import(
    "helpers/getCommonServerSideProps"
  );
  const { commonProps } = await getCommonServerSideProps(ctx);

  return {
    props: { ...commonProps },
  };
};

const LoginScreenWrapper = (props: any) => {
  return <LoginScreen {...props} />;
};

LoginScreenWrapper.getLayout = function getLayout(page: ReactElement) {
  const { props } = page;
  return <LoginLayout config={props.config}>{page}</LoginLayout>;
};

export default LoginScreenWrapper;
